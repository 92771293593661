<template>
    <answer-panel
    url="/api/collectQuestions/getCollectPage"
    >
        <template slot="top">
            <div>
               <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                            <el-breadcrumb-item>我的收藏</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <div class="title">我的收藏</div>
        </template>

    </answer-panel>
</template>

<script>
 
import AnswerPanel from "../components/AnswerPanel.vue"
 
    export default {
       
        components: {
         
            AnswerPanel
           
        }

    }
</script>

 